$(function(){

	if(!Modernizr.svg) {
  $("img.img").attr('src', function() {
      return $(this).attr('src').replace('.svg', '.png');
  	});
  
	}
//Carousel
$('#slider').carousel({
  interval: 4000
});

$('.carousel-indicators li').click(function(e){
        e.stopPropagation();
        var goTo = $(this).data('slide-to');
        $('.carousel-inner .item').each(function(index){
            if($(this).data('id') == goTo){
                goTo = index;
                return false;
            }
        });

        $('#slider').carousel(goTo); 
    });

// Slide Down
$("#scroll-down").click(function (){
    $('html, body').animate({
      scrollTop: $("#couponer-dashboard").offset().top
      }, 2000);
});

$("#scroll-down-meshmasher").click(function (){
    $('html, body').animate({
      scrollTop: $("#meshmasher-slide1").offset().top
      }, 2000);
});
    $('[data-toggle="tooltip"]').tooltip();

// Category List and user menu
$("#categories span").click(function(e){
  $("#categories #cat-list").slideToggle();
    $("#user-menu >li ul").slideUp();
    $("#user-menu >li").removeClass('open');
  e.stopPropagation();
});

$("#user-menu >li").click(function(e){
  $(this).toggleClass('open');
  $("#user-menu > li ul").slideToggle();
    $("#categories #cat-list").slideUp();
  e.stopPropagation();
});

$("body").click(function(){
  $("#categories #cat-list, #user-menu >li ul").slideUp();
    $("#user-menu >li").removeClass('open');

  // e.stopPropagation();
});

$(".coupon, .arrow").fadeIn();
});


$('.agreement a').hide();
//Agreement
$('.agreement').scroll(function(){
    if ($(this).scrollTop() > 100) {
        $('.agreement a').fadeIn();
    } else {
        $('.agreement a').fadeOut();
    }
});


function submitAllTerms() {
    let englishTerms = '',
        arabicTerms = '';
    const englishTermsBox = $("#coupon_en_terms");
    const arabicTermsBox = $("#coupon_ar_terms");
    $('input[type="checkbox"][name="term"]:checked').each(function() {
        englishTerms += this.value + '\n';
        arabicTerms += this.getAttribute('data-value')+ '\n';
    });
    englishTermsBox.val((englishTermsBox.val().trim() + '\n' + englishTerms).trim());
    arabicTermsBox.val((arabicTermsBox.val().trim() + '\n' + arabicTerms).trim());
}
